
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Components
import {
  DataBoard,
  Football,
  DataLine,
  DataAnalysis,
  FirstAidKit,
  User,
  FolderOpened,
} from "@element-plus/icons-vue";
import AuthenticationLogoutIndexVue from "@/views/authentication/logout/index.vue";
import SwitchOrganizationIndexVue from "@/components/switch-organization.vue";

@Options({
  components: {
    DataBoard,
    Football,
    DataLine,
    DataAnalysis,
    FirstAidKit,
    User,
    FolderOpened,
    AuthenticationLogoutIndexVue,
    SwitchOrganizationIndexVue,
  },
})
export default class CoachNavigationVue extends Vue {
  isNavigationListVisible = false;
  exactRoute = "";

  setExactRoute(): void {
    switch (this.$route.name) {
      case "AppCoachDashboardRootIndexVue":
      case "AppCoachDashboardStatisticsIndexVue":
        this.exactRoute = "dashboard";
        break;
      case "AppCoachMatchesRootIndexVue":
      case "AppCoachMatchesCategoriesIndexVue":
        this.exactRoute = "matches";
        break;
      case "AppCoachTrainingsRootIndexVue":
      case "AppCoachTrainingsCategoriesIndexVue":
        this.exactRoute = "trainings";
        break;
      case "AppCoachDiagnosticsRootIndexVue":
      case "AppCoachDiagnosticsCategoriesIndexVue":
        this.exactRoute = "diagnostics";
        break;
      case "AppCoachHealthRecordsRootIndexVue":
        this.exactRoute = "health-records";
        break;
      case "AppCoachPlayersRootIndexVue":
      case "AppCoachPlayersCategoriesIndexVue":
      case "AppCoachPlayersProfileRootIndexVue":
      case "AppCoachPlayersProfileStatisticsIndexVue":
      case "AppCoachPlayersProfileMatchesIndexVue":
      case "AppCoachPlayersProfileTrainingsIndexVue":
      case "AppCoachPlayersProfileDiagnosticsIndexVue":
      case "AppCoachPlayersProfileHealthRecordsIndexVue":
      case "AppCoachPlayersProfileTipsIndexVue":
      case "AppCoachPlayersProfileNotesIndexVue":
        this.exactRoute = "players";
        break;
      case "AppCoachResourcesRootIndexVue":
      case "AppCoachResourcesSupplementsIndexVue":
      case "AppCoachResourcesDietsIndexVue":
      case "AppCoachResourcesExercisesIndexVue":
        this.exactRoute = "resources";
        break;
    }
  }

  get hasUserOrganizationMemberships(): boolean {
    return store.getters.getOrganizationMembership.length > 1;
  }

  created(): void {
    this.setExactRoute();
  }
}
