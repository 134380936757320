
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Vendors
import moment from "moment";

// Models
import {AppCommonUserInformation} from "@/models/app/common/user-information";
import {ResponseIndex} from "@/models/response";

// Components
import CoachPlayersProfileRootUserInformationFormUpdateAvatarIndexVue from "@/views/app/coach/players/profile/root/user-information/form/update-avatar.vue";
import CoachPlayersProfileRootUserInformationFormUpdateInformationIndexVue from "@/views/app/coach/players/profile/root/user-information/form/update-information.vue";
import CoachPlayersProfileRootUserInformationFormUpdateContractIndexVue from "@/views/app/coach/players/profile/root/user-information/form/update-contract.vue";
import CoachPlayersProfileRootUserInformationFormUpdateSponsorIndexVue from "@/views/app/coach/players/profile/root/user-information/form/update-sponsor.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    CoachPlayersProfileRootUserInformationFormUpdateAvatarIndexVue,
    CoachPlayersProfileRootUserInformationFormUpdateInformationIndexVue,
    CoachPlayersProfileRootUserInformationFormUpdateContractIndexVue,
    CoachPlayersProfileRootUserInformationFormUpdateSponsorIndexVue,
  },
})
export default class CoachPlayersProfileRootUserInformationIndexVue extends Vue {
  isLoading: boolean | null = false;
  isUserInformationVisible: boolean | null = false;

  userInformation: AppCommonUserInformation = new AppCommonUserInformation();

  getAvatar(data: string): string {
    return data.length ? data : "/images/other/placeholder-avatar.jpg";
  }

  getBirthday(data: string): string {
    return data.length ? moment(data).format("DD.MM.YYYY.") : "N/A";
  }

  getFoot(data: string): string {
    return data ? `${data}` : "N/A";
  }

  getPosition(data: string): string {
    let position = "";

    switch (data) {
      case "goalkeeper":
        position = "Golman";
        break;
      case "defender":
        position = "Branič";
        break;
      case "midfielder":
        position = "Veznjak";
        break;
      case "striker":
        position = "Napadač";
        break;
      default:
        position = "N/A";
    }
    return position;
  }

  getType(data: string): string {
    let type = "";

    switch (data) {
      case "scholarship":
        type = "Stipendijski";
        break;
      case "professional":
        type = "Profesionalni";
        break;
      default:
        type = "N/A";
    }
    return type;
  }

  getHeight(data: string): string {
    return data ? `${data}cm` : "N/A";
  }

  getWeight(data: string): string {
    return data ? `${data}kg` : "N/A";
  }

  getContractClub(data: string): string {
    return data.length ? data : "Bez kluba";
  }

  hasUserSignedContract(data: string): boolean {
    return !!data.length;
  }

  getSponsorName(data: string): string {
    return data.length ? data : "Bez sponzora";
  }

  hasUserSignedSponsor(data: string): boolean {
    return !!data.length;
  }

  async getUserInformation(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/user/information`,
      formData: {
        id: this.$route.params.id,
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.userInformation = r.data;
      }
    });
    this.isLoading = false;
  }

  setUserInformationVisibility(): void {
    this.isUserInformationVisible = !this.isUserInformationVisible;
  }

  async created(): Promise<void> {
    await this.getUserInformation();
  }
}
