
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// Models
import {CoachPlayersProfileRootUserInformationFormUpdate} from "@/models/app/coach/players/profile/root/user-information/form/update";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElButton,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "userInformation",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElButton,
    Cropper,
    Edit,
  },
})
export default class CoachPlayersProfileRootUserInformationFormUpdateAvatarIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  userInformation: CoachPlayersProfileRootUserInformationFormUpdate = new CoachPlayersProfileRootUserInformationFormUpdate();

  formData: CoachPlayersProfileRootUserInformationFormUpdate = new CoachPlayersProfileRootUserInformationFormUpdate();

  $refs!: {
    formComponent: HTMLFormElement;
    cropper: any;
    file: any;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    const {canvas} = this.$refs.cropper.getResult();

    if (canvas) {
      this.formData.content.avatar = canvas.toDataURL();

      this.isLoading = true;
      await postRequest({
        uri: "/common/user/information/update",
        formData: this.formData,
        isProtected: true,
        isSuccessNotificationVisible: true,
        isErrorNotificationVisible: true,
      }).then((r: ResponseIndex) => {
        if (r.status === "success") {
          this.$emit('getUserInformation');
          this.isDialogVisible = false;
          this.resetFormData();
        }
      });
      this.isLoading = false;
    }
  }

  setFormData(): void {
    this.formData = this.userInformation;
  }

  resetFormData(): void {
    this.formData = new CoachPlayersProfileRootUserInformationFormUpdate();
  }

  updated(): void {
    this.setFormData();
  }

  choosePhoto(event): void {
    const {files} = event.target;

    if (files && files[0]) {
      if (this.formData.content.avatar) {
        URL.revokeObjectURL(this.formData.content.avatar)
      }

      const blob = URL.createObjectURL(files[0]);
      const reader = new FileReader();

      reader.onload = () => {
        this.formData.content.avatar = blob;
      };

      reader.readAsArrayBuffer(files[0]);
    }
  }
}
